// import store from '../store/index'

const homeRouter = [
    {
        path: "",
        redirect: "index",
    },
    {
        path: "index",
        meta: {
            title: "总览",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/index"),
    },
    {
        path: "approval",
        meta: {
            title: "我审批的",
            needLogin: true, // 是否需要登录才能访问
            keepAlive: true,
        },
        component: () => import("../views/overviewChildren/approval"),
    },
    {
        path: "todo",
        meta: {
            title: "我待办的",
            needLogin: true, // 是否需要登录才能访问
            keepAlive: true,
        },
        component: () => import("../views/overviewChildren/todo"),
    },
    {
        path: "subApproval",
        meta: {
            title: "行政审批",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/subApproval"),
    },
    {
        path: "subSign",
        meta: {
            title: "签批",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/subSign"),
    }, {
        path: "signDetail",
        name: "signDetail",
        meta: {
            title: "签批",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/signDetail"),
    },
    {
        path: "xzregister",
        meta: {
            title: "公章登记",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/projectChildren/xzregister"),
    },
    {
        path: "businessStatistics",
        meta: {
            title: "业务量统计",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/projectChildren/businessStatistics"),
    },
    {
        path: "approvalDetail",
        name: "approval",
        meta: {
            title: "行政审批",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/approvalDetail"),
    },
    {
        path: "subMerchants",
        meta: {
            title: "招商资料",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/subMerchants"),
    },
    {
        path: "merchantsDetail",
        name: "merchants",
        meta: {
            title: "招商资料",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/merchantsDetail"),
    },
    {
        path: "subSponsor",
        meta: {
            title: "上游协议",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/subSponsor"),
    },

    {
        path: "sponsorDetail",
        name: "sponsor",
        meta: {
            title: "上游协议",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/sponsorDetail"),
    },
    {
        path: "payment",
        meta: {
            title: "到款",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/payment"),
    },
    {
        path: "paymentDetail",
        name: "payment",
        meta: {
            title: "到款",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/paymentDetail"),
    },
    {
        path: "invoice",
        meta: {
            title: "发票申请",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/invoice"),
    },
    {
        path: "invoiceDetail",
        name: "invoice",
        meta: {
            title: "发票申请",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/invoiceDetail"),
    },
    {
        path: "contract",
        meta: {
            title: "下游协议",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/contract"),
    },
    {
        path: "contractDetail",
        name: "contract",
        meta: {
            title: "下游协议",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/contractDetail"),
    },
    {
        path: "parity",
        meta: {
            title: "供应商比价",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/parity"),
    },
    {
        path: "parityDetail",
        name: "parity",
        meta: {
            title: "供应商比价",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/parityDetail"),
    },
    {
        path: "scontract",
        meta: {
            title: "特殊下游协议",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/scontract"),
    },
    {
        path: "scontractDetail",
        name: "scontract",
        meta: {
            title: "特殊下游协议",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/scontractDetail"),
    },
    {
        path: "pay",
        meta: {
            title: "项目付款",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/pay"),
    },
    {
        path: "payLw",
        meta: {
            title: "劳务付款",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/payLw"),
    },
    {
        path: "refund",
        meta: {
            title: "退款",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/refund"),
    },
    {
        path: "payDetail",
        name: "pay",
        meta: {
            title: "项目付款",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/payDetail"),
    }, {
        path: "payLwDetail",
        name: "payLw",
        meta: {
            title: "劳务付款",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/payLwDetail"),
    }, {
        path: "refundDetail",
        name: "refund",
        meta: {
            title: "退款",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/refundDetail"),
    },
    {
        path: "otherPay",
        meta: {
            title: "支票申请",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/otherPay"),
    },
    {
        path: "otherPayDetail",
        name: "otherPay",
        meta: {
            title: "支票申请",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/otherPayDetail"),
    },
    {
        path: "fpr",
        meta: {
            title: "结项",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/fpr"),
    },
    {
        path: "fprDetail",
        name: "fpr",
        meta: {
            title: "结项",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/fprDetail"),
    },
    {
        path: "otherFpr",
        name: "otherFpr",
        meta: {
            title: "专项结项",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/otherFpr"),
    },
    {
        path: "otherFprDetail",
        name: "otherFprDetail",
        meta: {
            title: "专项结项",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/otherFprDetail"),
    },
    {
        path: "otherApproval",
        name: "otherApproval",
        meta: {
            title: "特殊审批",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/otherApproval"),
    },
    {
        path: "advance",
        name: "advance",
        meta: {
            title: "垫付申请",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/advance"),
    },
    {
        path: "advanceDetail",
        name: "advanceDetail",
        meta: {
            title: "垫付申请",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/advanceDetail"),
    },
    {
        path: "otherApprovalDetail",
        name: "otherApprovalDetail",
        meta: {
            title: "特殊审批",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/otherApprovalDetail"),
    },
    {
        path: "RateDetail",
        name: "rate",
        meta: {
            title: "费率",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/RateDetail"),
    },
    {
        path: "send",
        meta: {
            title: "我发起的",
            needLogin: true, // 是否需要登录才能访问
            keepAlive: true,
        },
        component: () => import("../views/overviewChildren/send"),
    },
    {
        path: "cc",
        meta: {
            title: "抄送我的",
            needLogin: true, // 是否需要登录才能访问
            keepAlive: true,
        },
        component: () => import("../views/overviewChildren/cc"),
    },
    {
        path: "systemMsg",
        meta: {
            title: "系统消息",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/system"),
    },
    {
        path: "dynamic",
        meta: {
            title: "动态",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/dynamic"),
    },
    {
        path: "project",
        meta: {
            title: "项目管理",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/projectChildren/project"),
    },
    {
        path: "projectDetail",
        name: "projectDetail",
        meta: {
            title: "项目立项",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/projectDetail"),
    },
    {
        path: "financialBook",
        meta: {
            title: "财务台账",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/projectChildren/financialBook"),
    },
    {
        path: "list",
        meta: {
            title: "项目列表",
            needLogin: true, // 是否需要登录才能访问
            keepAlive: true,
        },
        component: () => import("../views/projectChildren/list"),
    },
    {
        path: "list/evaluation",
        meta: {
            title: "供应商评价",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/projectChildren/evaluation"),
    },
    {
        path: "approvalPro",
        meta: {
            title: "项目审批",
            needLogin: true, // 是否需要登录才能访问
            keepAlive: true,
        },
        component: () => import("../views/projectChildren/approvalPro"),
    },
    {
        path: "approvalProDetail",
        name: "approvalProDetail",
        meta: {
            title: "项目审批",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/projectChildren/approvalProDetail"),
    },
    {
        path: "detail",
        name: "detail",
        meta: {
            title: "项目详情",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/projectChildren/detail"),
    },
    {
        path: "statistics",
        name: "statistics",
        meta: {
            title: "数据统计",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/projectChildren/statistics"),
    },
    {
        path: "to_statistics",
        name: "to_statistics",
        meta: {
            title: "到账统计",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/projectChildren/to_statistics"),
    },
    {
        path: "sponsorReward",
        name: "sponsorReward",
        meta: {
            title: "赞助回报汇总",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/projectChildren/sponsorReward"),
    },
    {
        path: "settlement",
        name: "settlement",
        meta: {
            title: "结算单",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/projectChildren/settlement"),
    },
    {
        path: "settlementDetail",
        name: "settlementDetail",
        meta: {
            title: "结算单详情",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/projectChildren/settlementDetail"),
    },

    {
        path: "add",
        meta: {
            title: "新建项目",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/projectChildren/add"),
    },
    {
        path: "register",
        meta: {
            title: "用章登记",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/projectChildren/register"),
    },
    {
        path: "xuehui",
        meta: {
            title: "学会管理",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/xuehuiChildren/xuehui"),
    },
    {
        path: "department",
        meta: {
            title: "部门管理",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/xuehuiChildren/department"),
    },
    {
        path: "team",
        meta: {
            title: "小组管理",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/xuehuiChildren/team"),
    },
    {
        path: "personnel",
        meta: {
            title: "人员管理",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/xuehuiChildren/personnel"),
    },
    {
        path: "level",
        meta: {
            title: "审批管理",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/xuehuiChildren/level"),
    },
    {
        path: "system",
        meta: {
            title: "系统管理",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/systemChildren/system"),
    },
    {
        path: "company/upstreams",
        meta: {
            title: "上游企业库",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/company/upstreams"),
    },
    {
        path: "company/downstreams",
        meta: {
            title: "下游企业库",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/company/downstreams"),
    },
    {
        path: "company/cooperation",
        meta: {
            title: "合作中心",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/company/cooperation"),
    },
    {
        path: "subCouncil",
        meta: {
            title: "理事会",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/subCouncil"),
    },
    {
        path: "passSet",
        meta: {
            title: "密码修改",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/systemChildren/passSet"),
    },
    {
        path: "councilDetail",
        name: "councilDetail",
        meta: {
            title: "理事会",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/councilDetail"),
    },
    {
        path: "subOtherSign",
        meta: {
            title: "特殊签批",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/subOtherSign"),
    },
    {
        path: "otherSignDetail",
        name: "otherSignDetail",
        meta: {
            title: "特殊签批",
            needLogin: true, // 是否需要登录才能访问
        },
        component: () => import("../views/overviewChildren/otherSignDetail"),
    },
];
export default homeRouter;

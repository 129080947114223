import Vue from "vue";
import md5 from "js-md5";
import router from '@/router/index'
import vue from "../main";
import store from "../store/index";
import CryptoJS from "crypto-js";


export function sub_name(name) {
    if (!name) return ''
    return name.length > 2 ? name.substring(name.length - 2, name.length) : name
}

export function webUrl(url) {
    let onlineViewType = ["doc", "docx", "xls", "xlsx", "xlsm", "ppt", "pptx"];
    let fileTypeName = url.substring(url.lastIndexOf(".") + 1, url.length).split("?")[0];
    let isWord = onlineViewType.find((type) => type === fileTypeName);
    if (isWord) {
//                    return "http://ow365.cn/?i=27768&ssl=1&furl=" + encodeURIComponent(decodeURIComponent(url));
        return "http://view.officeapps.live.com/op/view.aspx?src=" + encodeURIComponent(decodeURIComponent(encodeSearchKey(url)));
//                    return "http://www.xdocin.com/xdoc?_func=to&_format=html&_cache=1&_xdoc=" + encodeURIComponent(decodeURIComponent(url));
    } else {
        return url;
    }
}
export function encodeSearchKey(key) {
    const encodeArr = [{
        code: '%',
        encode: '%25'
    }, {
        code: '?',
        encode: '%3F'
    }, {
        code: '#',
        encode: '%23'
    }, {
        code: '&',
        encode: '%26'
    }, {
        code: '=',
        encode: '%3D'
    }];
    return key.replace(/[%?#&=]/g, ($) => {
        for (const k of encodeArr) {
            if (k.code === $) {
                return k.encode;
            }
        }
    });
}

export const downloadFile = (data,fileName) =>{
    let url = window.URL.createObjectURL(new Blob([data]));
    let link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}

// 时间戳转时间 now：当前时间  type:1 年月日时分秒  else
export function ZHDate(now, type) {
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    let date = now.getDate();
    let hour = now.getHours();
    let minute = now.getMinutes();
    let second = now.getSeconds();
    if (type == 1) {
        return (year + "-" + getzf(month) + "-" + getzf(date) + "  " + getzf(hour) + ":" + getzf(minute));
    } else if (type == 2) {
        return getzf(hour) + ":" + getzf(minute);
    } else if (type == 6) {
        return (year + "-" + getzf(month) + "-" + getzf(date) + " " + getzf(hour) + ":" + getzf(minute) + ":" + getzf(second));
    } else {
        return year + "-" + getzf(month) + "-" + getzf(date);
    }
}

function getzf(num) {
    if (parseInt(num) < 10) {
        num = "0" + num;
    }
    return num;
}

function goBack() {
    vue.$router.go(-1);
}

function checkTel(tel) {
    //验证手机号
    if (!/^1[3456789]\d{9}$/.test(tel)) {
        return true;
    } else {
        return false;
    }
}

function SHA256(value, key) {
    let obj = objKeySort(value);
    let str = serialize(obj) + "&key=" + store.state.user.key;
    let a = CryptoJS.HmacSHA256(str, key);
    var hashInBase64 = CryptoJS.enc.Hex.stringify(a).toUpperCase();
    return hashInBase64;
}

// js对象中的key按照a-z排序 字典升序
function objKeySort(arys) {
    //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
    var newkey = Object.keys(arys).sort();
    //console.log('newkey='+newkey);
    var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
    for (var i = 0; i < newkey.length; i++) {
        //遍历newkey数组
        newObj[newkey[i]] = arys[newkey[i]];
        //向新创建的对象中按照排好的顺序依次增加键值对
    }
    return newObj; //返回排好序的新对象
}

function serialize(obj, prefix) {
    var str = [],
        p;
    for (p in obj) {
        var k = prefix ? prefix + "[" + p + "]" : p,
            v = obj[p];
        if (v !== "") {
            str.push(
                v !== null && typeof v === "object"
                    ? serialize(v, k)
                    : // encodeURIComponent(k) + "=" + encodeURIComponent(v));
                    k + "=" + v
            );
        }
    }
    return str.join("&");
}

function requestTime() {
    return Date.parse(new Date()) / 1000;
}

function sealTypes() {
    return [
        //1公章 2合同章 3法人章 4其他
        {
            value: 1,
            label: "公章",
        },
        {
            value: 2,
            label: "合同章",
        },
        {
            value: 3,
            label: "法人章",
        },
        {
            value: 4,
            label: "其他",
        },
    ];
}

function getSealTypes(type) {
    return [
        //1公章 2合同章 3法人章 4其他
        "公章",
        "合同章",
        "法人章",
        "其他",
    ](type - 1);
}

function approvalClassify() {
    return [
        {
            value: 0,
            label: "全部",
        },
        {
            value: 1,
            label: "行政审批",
        },
        {
            value: 2,
            label: "招商资料",
        },
        {
            value: 3,
            label: "上游协议",
        },
        {
            value: 4,
            label: "到款",
        },
        {
            value: 5,
            label: "发票",
        },
        {
            value: 24,
            label: "供应商比价",
        },
        {
            value: 6,
            label: "下游协议",
        },
        {
            value: 17,
            label: "特殊下游协议",
        },
        {
            value: 9,
            label: "项目付款",
        },
        {
            value: 22,
            label: "劳务付款",
        },
        {
            value: 23,
            label: "退款",
        },
        {
            value: 7,
            label: "支票申请",
        },
        {
            value: 8,
            label: "结项",
        },
        {
            value: 13,
            label: "专项结项",
        },
        {
            value: 14,
            label: "特殊审批",
        },
        {
            value: 15,
            label: "垫付申请",
        },
        {
            value: 10,
            label: "项目费率",
        },
        {
            value: 11,
            label: "会议费率",
        },
        {
            value: 12,
            label: "专项费率",
        },
        {
            value: 18,
            label: "项目立项",
        },
        {
            value: 19,
            label: "签批",
        },
        {
            value: 21,
            label: "特殊签批",
        },
        {
            value: 20,
            label: "理事会",
        },
    ];
}

export const getApprovalType = (type) => {
    switch (Number(type)) {
        case 1:
            return "行政审批";
        case 2:
            return "招商资料";
        case 3:
            return "上游协议";
        case 4:
            return "到款";
        case 5:
            return "发票";
        case 24:
            return "供应商比价";
        case 6:
            return "下游协议";
        case 17:
            return "特殊下游协议";
        case 7:
            return "支票申请";
        case 8:
            return "结项";
        case 9:
            return "项目付款";
        case 22:
            return "劳务付款";
        case 23:
            return "退款";
        case 13:
            return "专项结项";
        case 14:
            return "特殊审批";
        case 15:
            return "垫付申请";
        case 18:
            return "项目立项";
        case 19:
            return "签批";
        case 21:
            return "特殊签批";
        case 20:
            return "理事会";
        default:
            return "费率申请";
    }
}

export const getPathIndex = (type) => {
    return Number(type) === 0 ? 1 : Number(type) === 1 ? 2 : 3
}

//type   //1我发起的 0 我审批的 2 抄送我的
export const toDetail = (row, type = -1) => {
    switch (Number(row.type)) {
        case 1:
            router.push({
                name: "approval",
                query: {id: row.oid, type: type, cctype: 1},
            });
            break;
        case 2:
            router.push({
                name: "merchants",
                query: {id: row.oid, type: type, cctype: 1},
            });
            break;
        case 3:
            router.push({
                name: "sponsor",
                query: {id: row.oid, type: type, cctype: 1},
            });
            break;
        case 4:
            router.push({
                name: "payment",
                query: {id: row.oid, type: type, cctype: 1},
            });
            break;
        case 5:
            router.push({
                name: "invoice",
                query: {id: row.oid, type: type, cctype: 1},
            });
            break;
        case 24:
            router.push({
                name: "parity",
                query: {id: row.oid, type: type, cctype: 1},
            });
            break;
        case 6:
            router.push({
                name: "contract",
                query: {id: row.oid, type: type, cctype: 1},
            });
            break;
        case 17:
            router.push({
                name: "scontract",
                query: {id: row.oid, type: type, cctype: 1},
            });
            break;
        case 7:
            router.push({
                name: "otherPay",
                query: {id: row.oid, type: type, cctype: 1},
            });
            break;
        case 8:
            router.push({name: "fpr", query: {id: row.oid, type: type, cctype: 1}});
            break;
        case 9:
            router.push({name: "pay", query: {id: row.oid, type: type, cctype: 1}});
            break;
        case 10:
            router.push({
                name: "rate",
                query: {id: row.oid, type: type, classify: 10, cctype: 1},
            });
            break;
        case 11:
            router.push({
                name: "rate",
                query: {id: row.oid, type: type, classify: 11, cctype: 1},
            });
            break;
        case 12:
            router.push({
                name: "rate",
                query: {id: row.oid, type: type, classify: 12, cctype: 1},
            });
            break;
        case 22:
            router.push({name: "payLw", query: {id: row.oid, type: type, cctype: 1}});
            break;
        case 23:
            router.push({name: "refund", query: {id: row.oid, type: type, cctype: 1}});
            break;
        case 13:
            router.push({
                name: "otherFprDetail",
                query: {id: row.oid, type: type, ccype: 1},
            });
            break;
        case 14:
            router.push({name: "otherApprovalDetail", query: {id: row.oid, type: type, cctype: 1}});
            break;
        case 15:
            router.push({name: "advanceDetail", query: {id: row.oid, type: type, cctype: 1}});
            break;
        case 18:
            router.push({
                name: "projectDetail",
                query: {id: row.oid, type: type, cctype: 1},
            });
            break;
        case 19:
            router.push({
                name: "signDetail",
                query: {id: row.oid, type: type, cctype: 1},
            });
            break;
        case 20:
            router.push({
                name: "councilDetail",
                query: {id: row.oid, type: type, cctype: 1},
            });
            break;
        case 21:
            router.push({
                name: "otherSignDetail",
                query: {id: row.oid, type: type, cctype: 1},
            });
            break;
        default:
            return "结项";
    }
}

/**
 * 项目分类判断项目类型
 * 1 普通项目
 * 2 科研项目
 * 3 项目类型
 */

export const PROJECT_TYPE_D = 1
export const PROJECT_TYPE_S = 2
export const PROJECT_TYPE_P = 3

export const isProjectType = (type) => {
    return getProjectType(type)
}

export const isProjectTypeD = (type) => {
    return getProjectType(type) === PROJECT_TYPE_D
}

export const isProjectTypeS = (type) => {
    return getProjectType(type) === PROJECT_TYPE_S
}

export const isProjectTypeP = (type) => {
    return getProjectType(type) === PROJECT_TYPE_P
}


function getProjectType(type) {
    return Number(type || 0) === 4 ? PROJECT_TYPE_S : Number(type || 0) === 3  ? PROJECT_TYPE_P : PROJECT_TYPE_D
}

/**
 * 项目类型判断字段名称
 *
 // {id: 1, name: "会议"}
 // {id: 2, name: "培训"}
 // {id: 3, name: "项目"}
 // {id: 4, name: "科研"}
 */
export const getTitleByType = (title, type) => {
    let isProjectType = getProjectType(type)
    type = Number(type || 1)
    switch (title) {
        case '会议日期' :
            return type  === 1 ? '会议日期' : type  === 2 ? '培训日期' : type  === 3 ? '项目日期' : '科研日期'
        case '会议名称' :
            return type  === 1 ? '会议名称' : type  === 2 ? '培训名称' : type  === 3 ? '项目名称' : '科研名称'
        case '会议' :
            return type  === 1 ? '会议' :  type  === 2 ? '培训' : type  === 3 ? '项目' : '科研'
        case '会议金额' :
            return  type  === 1 ? '会议金额' :  type  === 2 ? '培训金额' : type  === 3 ? '项目金额' : '科研金额'
        case '会议基金' :
            return  type  === 1 ? '会议基金' :  type  === 2 ? '培训基金' : type  === 3 ? '项目基金' : '科研基金'
        case '供应商名称' :
            return  type  === 4 ?  '供应商/合作中心名称' : '供应商名称'
        case '大会主席' :
            return isProjectType  === PROJECT_TYPE_D ? '大会主席' : isProjectType  === PROJECT_TYPE_P ? '项目发起方' : '主要研究者'
        case '项目单场规模' :
            return isProjectType === PROJECT_TYPE_D ? '项目单场规模' : '项目规模'
        case '大会主席/项目负责人' :
            return isProjectType === PROJECT_TYPE_D ? '大会主席/项目负责人' : '主要研究者/项目负责人'
        case '是否需要委托会议执行公司' :
            return isProjectType === PROJECT_TYPE_D ? '是否需要委托会议执行公司' : '是否需要委托执行公司'
        default :
            return ''
    }
}

export const projectTypes = [{
    key: 1,
    type: PROJECT_TYPE_D,
    value: '单场会议'
}, {
    key: 2,
    type: PROJECT_TYPE_D,
    value: '系列会议'
}, {
    key: 3,
    type: PROJECT_TYPE_D,
    value: '项目'
},{
    key: 1,
    type: PROJECT_TYPE_P,
    value: '单场会议'
}, {
    key: 2,
    type: PROJECT_TYPE_P,
    value: '系列会议'
}, {
    key: 3,
    type: PROJECT_TYPE_P,
    value: '项目'
}, {
    key: 1,
    type: PROJECT_TYPE_S,
    value: '基础研究'
}, {
    key: 2,
    type: PROJECT_TYPE_S,
    value: '动物研究'
}, {
    key: 3,
    type: PROJECT_TYPE_S,
    value: '临床研究'
}]

export const projectFroms = [{
    key: 1,
    value: '线下会议'
}, {
    key: 2,
    value: '线上会+线下会'
}, {
    key: 3,
    value: '线上会议'
}, {
    key: 4,
    value: '其他'
}]

export const projectScales = [{
    key: 1,
    value: '50人以下'
}, {
    key: 2,
    value: ' 51-100人'
}, {
    key: 3,
    value: '101-150人'
}, {
    key: 4,
    value: '151-200人'
}, {
    key: 5,
    value: '200人以上'
}]


export const projectNums = [{
    key: 1,
    value: '单中心'
}, {
    key: 2,
    value: '多中心'
}]


export const companyStates = [
    {
        key: '',
        value: '全部'
    }, {
        key: 0,
        value: '正常'
    }, {
        key: 1,
        value: '黑名单'
    }]



export const getQNFileName = (name) => {
    return `${name}`
}
export const getQNFileKey = (name) => {
    return `${new Date().getTime()}${name}`
}
const tools = {
    ZHDate,
    goBack,
    checkTel,
    SHA256,
    requestTime,
    sealTypes,
    getSealTypes,
    approvalClassify,
    getTitleByType,
    projectNums,
    companyStates,
};

Vue.prototype.$md5 = md5;
Vue.prototype.$tools = tools;
Vue.prototype.$getTitleByType = getTitleByType;
Vue.prototype.$isProjectType = isProjectType;
Vue.prototype.$isProjectTypeD = isProjectTypeD;
Vue.prototype.$isProjectTypeS = isProjectTypeS;
Vue.prototype.$isProjectTypeP = isProjectTypeP;

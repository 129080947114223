import vue from "vue";
import axios from "./axios";

const apiContent = {
    /*
     * 登录注册
     * */
    //  密码登录接口
    passLogin: (data) => {
        return axios("/Home/User/login", data);
    },
    //  手机验证码登录接口
    codeLogin: (data) => {
        return axios("", data);
    },
    //  找回密码
    resetPass: (data) => {
        return axios("/Home/User/forget", data);
    },
    //  修改密码
    setPass: (data) => {
        return axios("/Home/User/setPass", data);
    },
    //  获取验证码
    sendMsg: (data) => {
        return axios("/Home/Index/sms_send", data);
    },
    //  ：总览--数据统计
    getCount: (data) => {
        return axios("/Home/Index/getCount", data);
    },
    //  获取系统通知列表
    getNotice: (data) => {
        return axios("/Home/Notice/getNotice", data);
    },
    // ：获取未读消息数量
    getUnreadNum: (data) => {
        return axios("/Home/Notice/getUnreadNum", data);
    },
    //项目即将锁定通知弹窗
    getProjectattachedNotice: (data) => {
        return axios("/home/Projectattached/getNotice", data);
    },
    //项目即将锁定通知弹窗
    noticeStop: (data) => {
        return axios("/home/Projectattached/noticeStop", data);
    },
    //  设为已读
    toRead: (data) => {
        return axios("/Home/Notice/read", data);
    },
    //  获取动态列表
    getDongtai: (data) => {
        return axios("/Home/Notice/getDongtai", data);
    },

    /*
     * 行政审批
     * */
    //  获取企业列表
    getCompanys: (data) => {
        return axios("/home/shenpi/getcorporatelist", data);
    },
    //  获取企业列表
    getAllCompanys: (data) => {
        return axios("/home/Shenpi/get_corporate_name_list", data);
    },
    //  获取企业列表
    getPayCompanys: (data) => {
        return axios("/home/Shenpi/get_payee_name_list", data);
    },
    //  获取联系人列表
    getUserList: (data) => {
        return axios("/Home/User/getUserList", data);
    },
    //  获取学会列表
    getSociety: (data) => {
        return axios("/Home/Index/getSociety", data);
    },
    //  获取学会列表
    getSocietyFromPro: (data) => {
        return axios("/Home/Index/getSocietyFromPro", data);
    },
    //  获取学会列表
    getAmountXie: (data) => {
        return axios("/home/Shenpi/getAmountXie", data);
    },
    //  获取合同
    getContractList: (data) => {
        return axios("/Home/Shenpi/getContractList", data);
    },
    //  获取项目审批的列表
    getConfirmList: (data) => {
        return axios("/home/Project/getConfirmList", data);
    },
    //  获取项目审批详情
    informationConform: (data) => {
        return axios("/home/Project/informationConform", data);
    },
    //  项目审批
    confirmSetMation: (data) => {
        return axios("/home/Project/confirmSetMation", data);
    },
    //  获取我审批的列表
    getApprovls: (data) => {
        return axios("/Home/Shenpi/myShenpi", data);
    },
    //  获取项目列表
    getProject: (data) => {
        return axios("/Home/Index/getProjects", data);
    },
    //  获取招商资料列表
    getMerchants: (data) => {
        return axios("/Home/Index/getZhaoshang", data);
    },
    //  获取招商资料列表
    getZhaoshang_teshu: (data) => {
        return axios("/Home/Index/getZhaoshang_teshu", data);
    },
    //  获取招商资料列表
    // getZhaoshangDao: (data) => {
    //     return axios("/Home/Index/getZhaoshangDao", data);
    // },
    //  获取招商资料列表
    getZhaoshangDao: (data) => {
        return axios("/Home/Index/getZhaoshangDao_upStream", data);
    },
    //  获取审批人列表
    getApprovalMembers: (data) => {
        return axios("/Home/Index/getShenpi", data);
    },
    //  获取审批人列表
    getFinance: (data) => {
        return axios("/home/Index/getFinance", data);
    },
    //  获取人员列表--选择审批人、抄送人
    getAllUser: (data) => {
        return axios("/Home/Index/getAllUser", data);
    },
    //  发布行政审批
    approvalXz: (data) => {
        return axios("/Home/Shenpi/xingzheng", data);
    },
    //  提交议题审核
    toppics: (data) => {
        return axios("/Home/shenpi/toppics", data);
    },
    //  提交议题审核
    edit_toppics: (data) => {
        return axios("/Home/shenpi/edit_toppics", data);
    },

    //  新建理事会
    addCouncil: (data) => {
        return axios("/home/examine_and_approve_council/add", data);
    },
    //  新建理事会
    editCouncil: (data) => {
        return axios("/home/examine_and_approve_council/edit", data);
    },

    // 议理事会--获取详情
    getCouncil: (data) => {
        return axios("/home/examine_and_approve_council/detail", data);
    },
    // 议理事会--获取详情
    del_council_sign: (data) => {
        return axios("/home/examine_and_approve_council/del_council_sign", data);
    },

    // 行政审批--获取详情
    getXingzheng: (data) => {
        return axios("/Home/Shenpi/getXingzheng", data);
    },
    // 议题审核--获取详情
    getTopics: (data) => {
        return axios("/Home/shenpi/getTopics", data);
    },
    // 发起人选择付款交接文件
    setPayment: (data) => {
        return axios("/home/Shenpi/setPayment", data);
    },
    // 发起人选择付款交接文件
    setRefundPayment: (data) => {
        return axios("/home/examine_and_approve_tuikuan/setPayment", data);
    },
    // 发起人选择付款交接文件
    setLwPayment: (data) => {
        return axios("/home/examine_and_approve_fukuan/setPayment", data);
    },
    // 发起人选择结算交接文件
    setSettlement: (data) => {
        return axios("/home/Shenpi/setSettlement", data);
    },
    // 项目锁定-业务办结-更新
    projectYeWuBanJieUpdate: (data) => {
        return axios("/home/project/projectYeWuBanJieUpdate", data);
    },
    // 项目锁定-业务办结-查询
    projectYeWuBanJieInfo: (data) => {
        return axios("/home/project/projectYeWuBanJieInfo", data);
    },
    // 发起人选择结算交接文件
    getSettlement: (data) => {
        return axios("/Home/Shenpi/statement", data);
    },
    // 获取评分项目
    getScoreType: (data) => {
        return axios("/home/enterprise/getScoreType", data);
    },
    // 获取待评价的供应商列表(选择其中一条)
    getEnterpriseQuery: (data) => {
        return axios("/home/enterprise/getEnterpriseQuery", data);
    },
    // 获取评价内容
    getEnterpriseScore: (data) => {
        return axios("/home/enterprise/getEnterpriseScore", data);
    },
    // 提交评价-业务
    addEnterpriseScore: (data) => {
        return axios("/home/enterprise/addEnterpriseScore", data);
    },
    // 发起人选择结算交接文件--特殊结项
    setSettlementEnd: (data) => {
        return axios("/home/Shenpi/setSettlementEnd", data);
    },
    //    发起人确认 特殊审批id
    contractConfirm: (data) => {
        return axios("/Home/Shenpi/contractConfirm", data);
    },
    //    发起人确认 招商审批id
    zhaoConfirm: (data) => {
        return axios("/Home/Shenpi/zhaoConfirm", data);
    },
    //    发起人确认 行政审批id
    xingConfirm: (data) => {
        return axios("/Home/Shenpi/xingConfirm", data);
    },
    //    发起人确认 上有合同审批id
    zanConfirm: (data) => {
        return axios("/Home/Shenpi/zanConfirm", data);
    },
    //    发起人确认  下游合同审批id
    heConfirm: (data) => {
        return axios("/Home/Shenpi/heConfirm", data);
    },
    //    特殊发起人确认  下游合同审批id
    heteshuConfirm: (data) => {
        return axios("/Home/Shenpi/heteshuConfirm", data);
    },
    // 垫付确认
    confirmAdvance: (data) => {
        return axios("/home/Shenpi/confirmAdvance", data);
    },
    // 撤销审批
    revoke: (data) => {
        return axios("/Home/Shenpi/revoke", data);
    },
    // 撤销审批
    del_toppics: (data) => {
        return axios("/Home/shenpi/del_toppics", data);
    },
    // 撤销审批
    del_toppics_sign: (data) => {
        return axios("/home/shenpi/del_toppics_sign", data);
    },
    // 赞助协议作废
    agreementToVoid: (data) => {
        return axios("/Home/Shenpi/agreementToVoid", data);
    },
    // 特殊付款发起人确认
    paymentConfirm: (data) => {
        return axios("/Home/Shenpi/paymentConfirm", data);
    },
    // 普通付款发起人确认
    setConfirm: (data) => {
        return axios("/Home/Shenpi/setConfirm", data);
    },
    // 普通付款发起人确认
    setRefundConfirm: (data) => {
        return axios("/Home/examine_and_approve_tuikuan/setConfirm", data);
    },
    // 普通付款发起人确认
    setLwConfirm: (data) => {
        return axios("/Home/examine_and_approve_fukuan/setConfirm", data);
    },
    // 普通付款财务存档确认
    setSureFlow: (data) => {
        return axios("/Home/Shenpi/sureFlow", data);
    },
    // 普通付款财务存档确认
    setRefundSureFlow: (data) => {
        return axios("/Home/examine_and_approve_tuikuan/sureFlow", data);
    },
    // 普通付款财务存档确认
    setLwSureFlow: (data) => {
        return axios("/Home/examine_and_approve_fukuan/sureFlow", data);
    },
    // 普通付款财务驳回确认
    revertStep: (data) => {
        return axios("/home/Shenpi/revertStep", data);
    },
    // 普通付款财务驳回确认
    revertRefundStep: (data) => {
        return axios("/home/examine_and_approve_tuikuan/revertStep", data);
    },
   // 普通付款财务驳回确认
    revertLwStep: (data) => {
        return axios("/home/examine_and_approve_fukuan/revertStep", data);
    },

    // 结项发起人确认
    setConfirmFpr: (data) => {
        return axios("/home/Shenpi/setEndConfirm", data);
    },

    // 特殊结项发起人确认
    setConfirmOtherFpr: (data) => {
        return axios("/home/Shenpi/setSpecialConfirm", data);
    },

    // 发票确认
    invoiceConfirm: (data) => {
        return axios("/Home/Shenpi/invoiceConfirm", data);
    },
    // 审批
    shenpi: (data) => {
        return axios("/Home/Shenpi/shenpi", data);
    },
    // 审批
    project_true: (data) => {
        return axios("/Home/project/project_true", data);
    },
    // 财务确认有无结转资金
    setRestMoney: (data) => {
        return axios("home/project/setRestMoney", data);
    },
    // 审批
    project_again: (data) => {
        return axios("/Home/project/project_again", data);
    },
    // 审批
    upFile: (data) => {
        return axios("/Home/Shenpi/upFile", data);
    },
    // 审批
    upRefundFile: (data) => {
        return axios("/Home/examine_and_approve_tuikuan/upFile", data);
    },
    // 审批
    upLwFile: (data) => {
        return axios("/Home/examine_and_approve_fukuan/upFile", data);
    },
    // 审批
    cheque: (data) => {
        return axios("/home/Shenpi/cheque", data);
    },
    // 费率审批
    projectShenpi: (data) => {
        return axios("/Home/Shenpi/projectShenpi", data);
    },
    // 费率确认
    confirm: (data) => {
        return axios("/home/Surface/confirm", data);
    },
    // 行政审批--添加用印文件
    xingzhengFile: (data) => {
        return axios("/Home/Shenpi/xingzhengFile", data);
    },
    //  招商资料--发起招商资料审批
    approvalZs: (data) => {
        return axios("/Home/Shenpi/zhaoshang", data);
    },
    //  招商资料--获取招商资料详情
    getZhaoshang: (data) => {
        return axios("/Home/Shenpi/getZhaoshang", data);
    },
    //  招商资料---添加用印文件
    zhaoshangFile: (data) => {
        return axios("Home/Shenpi/zhaoshangFile", data);
    },
    //  赞助协议--发起赞助协议审批
    approvalZz: (data) => {
        return axios("/Home/Shenpi/zanzhu", data);
    },
    //  提交特殊审批
    teshuContract: (data) => {
        return axios("/Home/Shenpi/teshuContract", data);
    },
    //  提交特殊审批详情
    getContract: (data) => {
        return axios("/home/Shenpi/getContract", data);
    },
    //  赞助协议--获取赞助协议详情
    getZanzhu: (data) => {
        return axios("/Home/Shenpi/getZanzhu", data);
    },
    //  垫付--发起垫付审批
    approvalAdvance: (data) => {
        return axios("/home/Shenpi/advance", data);
    },
    //  垫付--获取垫付详情
    getAdvance: (data) => {
        return axios("/home/Shenpi/getAdvance", data);
    },
    //  赞助协议--上传用印文件
    zanzhuFile: (data) => {
        return axios("/Home/Shenpi/zanzhuFile", data);
    },
    //  赞助协议--获取赞助协议列表
    getZanzhuList: (data) => {
        return axios("/Home/Index/getZanzhu", data);
    },
    //  赞助协议--获取赞助协议列表
    getZanzhuTuiKuan: (data) => {
        return axios("/Home/Index/getZanzhuTuiKuan", data);
    },
    //  退款-根据项企业名称获取特殊审批列表
    getSpecialQueryByName: (data) => {
        return axios("/Home/examine_And_Approve_Tuikuan/getSpecialQueryByName", data);
    },
    //  到款--发起到款审批
    approvalDk: (data) => {
        return axios("/Home/Shenpi/daokuan", data);
    },
    //  到款--获取到款详情
    getDaokuan: (data) => {
        return axios("/Home/Shenpi/getDaokuan", data);
    },
    //  项目立项--获取项目立项详情
    getApProjectDetail: (data) => {
        return axios("/Home/project/getProject", data);
    },
    //  发票--发起发票申请
    approvalFP: (data) => {
        return axios("/Home/Shenpi/fapiao", data);
    },
    //  发票---获取发票详情
    getFapiao: (data) => {
        return axios("/Home/Shenpi/getFapiao", data);
    },
    //  合同--发起到款审批
    approvalHt: (data) => {
        return axios("/Home/Shenpi/hetong", data);
    },
    //  特殊合同--发起到款审批
    teshuhetong: (data) => {
        return axios("/Home/Shenpi/teshuhetong", data);
    },
    //  合同--发起到款详情
    getHetong: (data) => {
        return axios("/Home/Shenpi/getHetong", data);
    },
    //  特殊合同--发起到款详情
    getteshuHetong: (data) => {
        return axios("/Home/Shenpi/getteshuHetong", data);
    },
    //  付款--获取普通付款
    approvalFk: (data) => {
        return axios("/Home/Shenpi/fukuan", data);
    },
    //  付款--获取普通付款详情
    getFukuan: (data) => {
        return axios("/Home/Shenpi/getFukuan", data);
    },
    //  付款--获取普通付款
    addRefund: (data) => {
        return axios("/Home/examine_and_approve_tuikuan/add", data);
    },
    //  付款--获取普通付款
    getTuikuan: (data) => {
        return axios("/Home/examine_and_approve_tuikuan/getTuikuan", data);
    },
    //  劳务付款-提交
    add_LabourServices: (data) => {
        return axios("/Home/examine_and_approve_fukuan/add_LabourServices", data);
    },
    //  劳务付款-提交
    getLwFukuan: (data) => {
        return axios("/Home/examine_and_approve_fukuan/getFukuan", data);
    },
    //  特殊付款--发起特殊付款审批
    approvalTSFk: (data) => {
        return axios("/Home/Shenpi/teshu", data);
    },
    //  ：特殊付款--获取特殊付款详情
    getTeshu: (data) => {
        return axios("/Home/Shenpi/getTeshu", data);
    },
    //  结项---发起结项审批
    approvalJX: (data) => {
        return axios("/Home/Shenpi/jiexiang", data);
    },
    //  结项---发起结项审批
    getJiexiang: (data) => {
        return axios("/Home/Shenpi/getJiexiang", data);
    },
    //  特殊结项---发起特殊结项审批
    approvalJXOhter: (data) => {
        return axios("/home/Shenpi/teshuEnd", data);
    },
    //  结项---发起结项审批
    getJiexiangOther: (data) => {
        return axios("/home/Shenpi/getTeshuEnd", data);
    },
    //  获取下游企业列表
    getParityEnterpriseList: (data) => {
        return axios("/home/enterprise_price_comparison/getEnterpriseList", data);
    },
    //  创建比价项目
    approvalParity: (data) => {
        return axios("/home/enterprise_price_comparison/createProject", data);
    },
 //  创建比价项目
    getParity: (data) => {
        return axios("/home/enterprise_price_comparison/infoProject", data);
    },
    //  创建比价项目
    selectPriceComparison: (data) => {
        return axios("/home/enterprise_price_comparison/selectPriceComparison", data);
    },
    //  创建比价项目
    delPriceComparison: (data) => {
        return axios("/home/enterprise_price_comparison/delPriceComparison", data);
    },
    //  删除供应商比价（郑）
    delEnterprisePriceComparison: (data) => {
        return axios("/home/enterprise_price_comparison/delEnterprisePriceComparison", data);
    },
    //  创建比价项目
    getPriceComparisonQuery: (data) => {
        return axios("/home/enterprise_price_comparison/getPriceComparisonQuery", data);
    },

    //  项目、会议、自用费率申请详情
    infoProject: (data) => {
        return axios("/Home/Surface/infoProject", data);
    },
    //  我发起的列表
    myInitiate: (data) => {
        return axios("/Home/Shenpi/myInitiate", data);
    },
    //  抄送我的列表
    myCopy: (data) => {
        return axios("/Home/Shenpi/myCopy", data);
    },
    //  设置优先级
    setLevel: (data) => {
        return axios("/Home/Shenpi/setLevel", data);
    },
    /**
     * 项目管理
     */
    //金额编辑
    editHandWriting: (data) => {
        return axios("/home/Surface/edit_hand_writing", data);
    },
    //发票编辑
    setInvoice: (data) => {
        return axios("/Home/surface/setInvoice", data);
    },
    //详情编辑
    editHandWritingDesc: (data) => {
        return axios("/home/Surface/edit_hand_writing_desc", data);
    },
    //获取台账列表
    getStandingBook: (data) => {
        return axios("/home/surface/standing_book", data);
    },
    //获取项目列表
    getProjectList: (data) => {
        return axios("/Home/Project/getList", data);
    },
    //添加项目列表
    addProject: (data) => {
        return axios("/Home/Project/add", data);
    },
    //  获取项目类别
    getProjectCat: (data) => {
        return axios("/Home/Project/getProjectCat", data);
    },
    //  项目锁定日志
    projectUnlockLog: (data) => {
        return axios("/home/Projectattached/projectUnlockLog", data);
    },
    //  项目添加
    projectAdd: (data) => {
        return axios("/Home/Project/add", data);
    },
    //  项目删除
    del_pro: (data) => {
        return axios("/Home/Project/del_pro", data);
    },
    //  项目添加
    projectEdit: (data) => {
        return axios("/Home/Project/edit", data);
    },
    //  获取项目详情
    getProjectDetail: (data) => {
        return axios("/Home/Project/detail", data);
    },
    //  修改资金统计数据
    setFund: (data) => {
        return axios("/Home/Project/setFund", data);
    },
    //  修改资金统计记录
    getFund: (data) => {
        return axios("/Home/Project/getFund", data);
    },

    //  会议统计--赞助汇总表
    // 用章登记导出（表1
    table1_export: (data) => {
        return axios("/Home/Statistics/table1_export", data);
    },
    //业务量统计
    statistics_traffic: (data) => {
        return axios("/home/record/statistics_traffic", data);
    },
    //业务量统计
    getShowRecordQuery: (data) => {
        return axios("/home/user/getShowRecordQuery", data);
    },
    //业务量统计
    setShowRecord: (data) => {
        return axios("/home/user/setShowRecord", data);
    },
    // 用章登记导出（表1
    table_xing_export: (data) => {
        return axios("/Home/Statistics/table_xing_export", data);
    },
    // 用章登记导出（表4
    table4_export: (data) => {
        return axios("/Home/Statistics/table4_export", data);
    },
    // 修改会议可用金额
    setKeyong: (data) => {
        return axios("/Home/Project/setKeyong", data);
    },
    //修改数据统计是否按比例计算
    setIsBili: (data) => {
        return axios("/Home/Project/setIsBili", data);
    },
    //修改数据统计是否按比例计算
    tablex1_excel: (data) => {
        return axios("/home/Statistics/tablex1_excel", data);
    },
    //修改数据统计是否按比例计算
    tablex1_down: (data) => {
        return axios("/home/Statistics/tablex2_excel", data,'post',true,true);
    },
    // 表x1--获取统计数据
    getTableX2: (data) => {
        return axios("/home/Surface/getTableX2", data);
    },
    // 表x1--获取统计数据
    getTableX1: (data) => {
        return axios("/home/Statistics/getTablex1", data);
    },
    //  会议统计--表X1下方备注添加
    setRemarks: (data) => {
        return axios("/Home/Surface/setRemarks", data);
    },
    //  会议统计--表X1下方备注添加
    getRemarks: (data) => {
        return axios("/Home/Surface/getRemarks", data);
    },
    //  会议统计--赞助汇总表 合同时间
    setInvoiceDate: (data) => {
        return axios("/home/Surface/setInvoiceDate", data);
    },
    //  会议统计--赞助汇总表 合同已回
    setContractState: (data) => {
        return axios("/home/Surface/setContractState", data);
    },
    //  会议统计--赞助汇总表 协议已回
    setSponsorState: (data) => {
        return axios("/home/Surface/setSponsorState", data);
    },
    //  会议统计--赞助汇总表 表x1--修改项目费率
    setProjectRate: (data) => {
        return axios("/home/Surface/setProjectRate", data);
    },
    applyProject: (data) => {
        return axios("/Home/Surface/applyProject", data);
    },
    //  会议统计--赞助汇总表 表x1--修改会议费率
    setMeetingOnly: (data) => {
        return axios("/home/Surface/setMeetingOnly", data);
    },
    applyMeeting: (data) => {
        return axios("/Home/Surface/applyMeeting", data);
    },
    //  会议统计--赞助汇总表 表x1--修改自用费率
    setSelfOnly: (data) => {
        return axios("/home/Surface/setSelfOnly", data);
    },
    applySelf: (data) => {
        return axios("/home/Surface/applySelf", data);
    },
    //  会议统计--赞助汇总表 添加数据
    inseSponsor: (data) => {
        return axios("/home/Surface/inseSponsor", data);
    },
    //  会议统计--赞助汇总表 表x1--编辑数据
    editSponsor: (data) => {
        return axios("/home/Surface/editSponsor", data);
    },
    //  会议统计--赞助汇总表 表x1--撤销
    withdraw: (data) => {
        return axios("/home/Surface/withdraw", data);
    },
    //  会议统计--赞助汇总表 表x1--撤销
    withdrawTablex1: (data) => {
        return axios("/home/Surface/withdrawTablex1", data);
    },
    //  会议统计--赞助汇总表 表x1--删除数据
    delSponsor: (data) => {
        return axios("/home/Surface/delSponsor", data);
    },
    // 会议统计--赞助汇总表 表x2--获取统计数据
    tablex2xStatistics: (data) => {
        return axios("/Home/Statistics/tablex2", data);
    },
    // 会议统计--赞助汇总表 表x2--获取统计数据
    getRuTableX: (data) => {
        return axios("/home/Surface/getRuTableX", data);
    },
    //  会议统计--赞助汇总表 表x1--添加数据
    tablex2Add: (data) => {
        return axios("/Home/Surface/tablex2_add", data);
    },
    //  会议统计--赞助汇总表 表x1--编辑数据
    tablex2Edit: (data) => {
        return axios("/Home/Surface/tablex2_edit", data);
    },
    //  会议统计--赞助汇总表 表x1--删除数据
    tablex2Del: (data) => {
        return axios("/Home/Surface/tablex2_del", data);
    },
    //  公章用印登记
    table_xing: (data) => {
        return axios("/Home/Statistics/table_xing", data);
    },
    //  公章用印登记
    table1Statistics: (data) => {
        return axios("/Home/Statistics/table1", data);
    },
    //  合同章用印登记
    table4Statistics: (data) => {
        return axios("/Home/Statistics/table4", data);
    },
    /*
     * 部门管理
     * */
    //  获取部门列表
    bumen: (data) => {
        return axios("/Home/User/bumen", data);
    },
    // 添加部门
    bumenAdd: (data) => {
        return axios("/Home/User/bumenAdd", data);
    },
    // 修改部门
    bumenEdit: (data) => {
        return axios("/Home/User/bumenEdit", data);
    },
    // 删除部门
    bumenDel: (data) => {
        return axios("/Home/User/bumenDel", data);
    },
    // 获取审批人列表
    approvalSysList: (data) => {
        return axios("/Home/Index/approvalSys", data);
    },
    // 设置审批人
    approvalOperation: (data) => {
        return axios("/Home/Index/approvalOperation", data);
    },
    // 获取人员列表
    userList: (data) => {
        return axios("/Home/User/getList", data);
    },
    // 获取职位列表
    getJobList: (data) => {
        return axios("/Home/Index/getJob", data);
    },
    // 添加人员
    userAdd: (data) => {
        return axios("/Home/User/add", data);
    },
    // 修改人员
    userEdit: (data) => {
        return axios("/Home/User/edit", data);
    },
    // 删除人员
    userDel: (data) => {
        return axios("/Home/User/del", data);
    },
    // 重置密码
    userResetPass: (data) => {
        return axios("Home/User/reset", data);
    },
    // 设置启用/停用
    setLock: (data) => {
        return axios("/Home/User/setLock", data);
    },
   // 设置启用/停用
    setUnlockRole: (data) => {
        return axios("/home/Projectattached/setUnlockRole", data);
    },

    //  上游协议-赞助回报列表
    getZanZhuHuiBao: (data) => {
        return axios("/home/zanzhu/getZanZhuHuiBao", data);
    },
    //  特殊结项结算表列表
    specialTableList: (data) => {
        return axios("/home/Surface/special_table_list", data);
    },
    //  创建特殊结项结算表
    specialTableIns: (data) => {
        return axios("/home/Surface/special_table_ins", data);
    },
    //  删除特殊结项结算表
    specialTableDel: (data) => {
        return axios("/home/Surface/special_table_del", data);
    },
    //  特殊结项结算表详情
    specialTableInfo: (data) => {
        return axios("/home/Surface/special_table_info", data);
    },
    //  特殊结项结算表详情
    specialTableInfos: (data) => {
        return axios("/home/Surface/special_table_infos", data);
    },
    //   修改特殊结项结算表
    specialTableSave: (data) => {
        return axios("/home/Surface/special_table_save", data);
    },

    //  结算表下拉列表
    getSpecialTable: (data) => {
        return axios("/home/index/getSpecialTable", data);
    },
    //导出 台账
    stand_excel: (data) => {
        return axios("/home/surface/stand_excel", data,'post',true,true);
    },
    //验证
    SendMsg: (data) => {
        return axios("/home/user/sendsms", data);
    },
    //小组列表
    groupList: (data) => {
        return axios("/home/user/groupList", data);
    },
    //小组列表 添加
    groupAdd: (data) => {
        return axios("/home/user/groupAdd", data);
    },
    //小组列表 添加
    groupEdit: (data) => {
        return axios("/home/user/groupEdit", data);
    },
    //小组列表
    departFromGroup: (data) => {
        return axios("/home/user/departFromGroup", data);
    },
    //企业库列表
    getEnterpriseList: (data) => {
        return axios("/home/Enterprise/query", data);
    },
    //黑名单-操作日志列表
    getBlackList: (data) => {
        return axios("/home/Enterprise/getBlackList", data);
    },
    //企业库列表
    exportEnterpriseList: (data) => {
        return axios("/home/Enterprise/exportExcel", data,'post',true,true);
    },
    //导出Excel - 供应商评价
    exportExcelScore: (data) => {
        return axios("home/Enterprise/exportExcelScore", data,'post',true,true);
    },
    //选择不需要比价，填写原因
    addbubijia: (data) => {
        return axios("/home/project/addbubijia", data);
    },
    //新增企业库
    addEnterprise: (data) => {
        return axios("/home/Enterprise/addEnterprise", data);
    },
    //新增企业库
    updateEnterprise: (data) => {
        return axios("/home/Enterprise/updateEnterprise", data);
    },
    //删除企业库
    delEnterprise: (data) => {
        return axios("/home/Enterprise/del", data);
    },
    //加入移除黑名单
    setBlackList: (data) => {
        return axios("/home/Enterprise/setBlackList", data);
    },
    //取消“优质供应商”标识（会长）
    removeEnterpriseQuality: (data) => {
        return axios("/home/enterprise/removeEnterpriseQuality", data);
    },
   //特殊签批-新建
    subSpecialEndorsement: (data) => {
        return axios("/Home/examine_and_approve/specialEndorsement", data);
    },
    //特殊签批-新建
    updateSpecialEndorsement: (data) => {
        return axios("/home/examine_and_approve/specialEndorsementUpdate", data);
    },
   //特殊签批-撤销
    revokeSpecialEndorsement: (data) => {
        return axios("/home/examine_and_approve/specialEndorsementRevoke", data);
    },
    //特殊签批-详情
    getSpecialEndorsement: (data) => {
        return axios("/Home/examine_and_approve/specialEndorsementDetail", data);
    },
    //移入待办列表
    setTodo: (data) => {
        return axios("/home/examine_and_approve/setTodo", data);
    },
    //关联该项目所有特殊审批的信息和资料
    getUpstreamDataSpecialEndorsement: (data) => {
        return axios("/home/examine_and_approve/UpstreamDataSpecialEndorsement", data);
    },
    //消息通知
    noticeUnreadClearAll: (data) => {
        return axios("/home/examine_and_approve/noticeUnreadClearAll", data);
    },
    //供应商报价列表-包含评价信息
    getPriceComparison_evaluation: (data) => {
        return axios("/home/Enterprise_Evaluation/getPriceComparison_evaluation", data);
    },
   //供应商报价列表-包含评价信息
    updatePriceComparison_evaluation: (data) => {
        return axios("/home/Enterprise_Evaluation/updatePriceComparison_evaluation", data);
    },
    //财务统计
    financial_statistics: (data) => {
        return axios("/home/Financial/statistics", data);
    },
    //审批驳回
    shenpi_rejected: (data) => {
        return axios("/Home/shenpi/shenpi_rejected", data);
    },
    //项目列表
    getAllProjectList: (data) => {
        return axios("/home/special/projectList", data);
    },
    //用户列表
    getAllUserList: (data) => {
        return axios("/home/Special/userList", data);
    },
    //项目一键转移
    projectTransfer: (data) => {
        return axios("/home/Special/projectTransfer", data);
    },
    //项目一键转移
    projectUnlock: (data) => {
        return axios("/home/Projectattached/projectUnlock", data);
    },


};
const api = {
    apiContent,
};
vue.prototype.$api = api;
